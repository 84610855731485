import { ActionableStore } from '@@/native_bridge/types'

interface PostMessageEvent {
  type?: string
  source?: unknown
  origin?: unknown
  data?: unknown
}

/**
 * Listens to post message calls. Accompany with @padlet/native-bridge@0.7.0 and above.
 * @param store A store that has a dispatch function we can use to dispatch actions.
 */
export default function connectStoreToPostMessage(store: ActionableStore): void {
  const listener = (event: PostMessageEvent): void => {
    let msgPayload
    const { data } = event
    if (typeof data === 'string') {
      if (data !== '') {
        try {
          msgPayload = JSON.parse(data)
        } catch (e) {
          // Json parsing issue
          msgPayload = {}
        }
      }
    } else {
      msgPayload = data
    }
    if (msgPayload) {
      if (msgPayload.type === 'dispatchStateAction') {
        const { action, payload } = msgPayload
        store.dispatch(action, payload)
      } else {
        // in development webpack sends a message with type `webpackOk`, `webpackInvalid`, etc.
        // console.error(`Error, unknown message type: ${msgPayload.type}, origin: ${event.origin}, source: ${event.source}`)
      }
    } else {
      // console.error(`Error: no msg payload`)
    }
  }
  window.addEventListener('message', listener)
}

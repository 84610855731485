// @file Initial (and likely static) states for surface vuex store from other imported modules (e.g. device info)
import appCan from '@@/bits/app_can'
import { browserCan } from '@@/bits/browser'
import { getCookie } from '@@/bits/cookie'
import getCsrfToken from '@@/bits/csrf_token'
import device, { getDprInteger } from '@@/bits/device'
import window, { browsingContextUid, ww } from '@@/bits/global'
import { isId } from '@@/bits/hashid_helper'
import { currentHostWithProtocol } from '@@/bits/location'
import type { Cid } from '@@/types'
import type { Environment, PostActionState, SurfaceState } from '@@/vuexstore/surface/types'

// Example: https://padlet.com/jshoung/my-padlet-te4vkxvfcpnt6mkx/wish/436
function getStartingExpandedPostCidOrHashid(): Cid | null {
  const splitPath = window.location.pathname.split('/wish/')

  // The other case is length === 1 where /wish/ doesn't exist
  // >2 shouldn't exist because /wish/ is a reserved keyword
  const isViewingExpandedPost = splitPath.length === 2
  if (!isViewingExpandedPost) {
    return null
  }

  // Systemic Authorization:
  // Check if hashid is passed instead of id. We consider it hashid if is not all integers
  const isHashid = !isId(splitPath[1])
  return isHashid ? splitPath[1] : `c${splitPath[1]}`
}

export const initialSurfaceState = (): Partial<SurfaceState> => ({
  /* CONSTANTS */
  canPostMessage: appCan('postMessage'),
  cookiesEnabled: browserCan('cookiesForReal'),
  csrfToken: getCsrfToken(),
  deviceId: getCookie('ww_d'),
  dir: document.documentElement.dataset.dir ?? 'ltr',
  dpr: getDprInteger(),
  environment: document.documentElement.dataset.environment as Environment,
  isAndroid: device.android,
  isApp: device.app,
  isChrome: device.chrome,
  isDesktop: device.desktop,
  isElectronApp: device.electronApp,
  isEdge: device.edge,
  isFirefox: device.firefox,
  isIE: device.ie,
  isIOS: device.ios,
  isIPhone: device.iphone,
  isIPhoneX: device.iphoneX,
  isMac: device.mac,
  isMobile: device.mobile,
  isMousable: device.mousable,
  isNativeMenu: appCan('showAlertSheet'),
  isOldApp: device.app && !appCan('postNatively'),
  isPhone: device.phone,
  isSafari: device.safari,
  isTablet: device.tablet,
  isTouchable: device.touchable,
  isWindows: device.windows,
  locale: document.documentElement.lang,
  modalPostEdit: device.mobile && !(device.app && appCan('postNatively')),
  nativePostEdit: device.app && appCan('postNatively'),
  release: document.documentElement.dataset.version ?? '',
  uid: ww?.uid ?? browsingContextUid,
  xHeader: !device.app || !appCan('useNativeHeader'),
  homeUrl: currentHostWithProtocol(),
})

export const initialPostActionState = (): Partial<PostActionState> => ({
  expandedPostCidOrHashid: getStartingExpandedPostCidOrHashid(),
})

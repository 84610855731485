/**
 * @file Installs global native bridge objects in the window.
 */

import { nativeBridgeReady } from '@@/native_bridge/actions'
import connectStoreToPostMessage from '@@/native_bridge/connect_store_to_post_message'
import installLegacy from '@@/native_bridge/legacy'
import { setupNativeBridgeListeners } from '@@/native_bridge/listen'
import * as stateActions from '@@/native_bridge/native_actions_map'
import postMessage from '@@/native_bridge/post_message'
import { ActionableStore, NativeBridgeWindow } from '@@/native_bridge/types'

export function install(window: NativeBridgeWindow, store: ActionableStore): void {
  connectStoreToPostMessage(store)
  setupNativeBridgeListeners(window)
  installLegacy(window, store)

  // Native bridge for app communication
  window.$nativeBridge = {
    dispatch: (fnName: string, ...args: any[]): void => {
      store.dispatch(stateActions[fnName].apply(null, args))
    },
  }

  postMessage(nativeBridgeReady())
}

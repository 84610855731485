import type { Cid } from '@@/types'

/**
 * @file Only being used for native bridge
 *
 * Mapping from old action name and shape using in the mobile app with
 * new action name which is being used in vuex store
 *
 * Usage: window.$nativeBridge.dispatch(fnName(args))
 */
interface ActionObject {
  type: String
  [key: string]: any
}
type FunctionOfDispatch = (dispatch: Dispatch) => any
type Action = ActionObject | FunctionOfDispatch
type Dispatch = (obj: Action) => void

export const CONNECTIVITY_OFFLINE = 'connectivityOffline'
export const goOffline = (): Action => {
  return { type: CONNECTIVITY_OFFLINE }
}

export const CONNECTIVITY_ONLINE = 'connectivityOnline'
export const goOnline = (): Action => {
  return { type: CONNECTIVITY_ONLINE }
}

export const START_NEW_POST = 'post/startNewPost'
export const startNewPost = (postAttributes = {}): Action => {
  return { type: START_NEW_POST, attributes: postAttributes }
}

export const START_EDITING_POST = 'post/startEditingPost'
export const startEditingPost = ({ postCid }: { postCid: Cid }): Action => {
  return { type: START_EDITING_POST, postCid }
}

export const SAVE_POST = 'post/savePost'
export const savePost = (postAttributes): Action => {
  return { type: SAVE_POST, attributes: postAttributes }
}
export const STOP_EDITING_POST = 'post/stopEditingPost'
export const stopEditingPost = (): Action => {
  return { type: STOP_EDITING_POST }
}

export const CREATE_POST = 'post/createPost'
export const createPost = (postAttributes): Action => {
  return { type: CREATE_POST, post: postAttributes }
}

export const COMPLETE_POST_UPLOAD = 'post/completePostUpload'
export const completePostUpload = (file, status): Action => {
  return { type: COMPLETE_POST_UPLOAD, file: file, status: status }
}

export const PICK_SECTION = 'post/pickSection'
export const pickSection = (sectionId): Action => {
  return { type: PICK_SECTION, sectionId: sectionId }
}

export const CANCEL_PICKING_SECTION = 'post/cancelPickingSection'
export const cancelPickingSection = (): Action => {
  return { type: CANCEL_PICKING_SECTION }
}

export const SHOW_CONTRIBUTORS_LIST = 'showContributorsList'
export const showContributorsList = (): Action => {
  return { type: SHOW_CONTRIBUTORS_LIST }
}

export const HIDE_CONTRIBUTORS_LIST = 'hideContributorsList'
export const hideContributorsList = (): Action => {
  return { type: HIDE_CONTRIBUTORS_LIST }
}

export const FETCHED_PADLET = 'fetchedPadlet'
export const fetchedPadlet = (): Action => {
  return { type: FETCHED_PADLET }
}

export const FETCHED_POSTS = 'fetchedPosts'
export const fetchedPosts = (): Action => {
  return { type: FETCHED_POSTS }
}

export const FETCHED_COMMENTS = 'fetchedComments'
export const fetchedComments = (): Action => {
  return { type: FETCHED_COMMENTS }
}

export const FETCHED_REACTIONS = 'fetchedReactions'
export const fetchedReactions = (): Action => {
  return { type: FETCHED_REACTIONS }
}

export const FETCHED_CONNECTIONS = 'fetchedConnections'
export const fetchedConnections = (): Action => {
  return { type: FETCHED_CONNECTIONS }
}

export const FETCHED_SECTIONS = 'fetchedSections'
export const fetchedSections = (): Action => {
  return { type: FETCHED_SECTIONS }
}

export const CHANGE_POST_TEXT = 'post/changePostText'
export const changePostText = (post, body): Action => {
  return { type: CHANGE_POST_TEXT, postCid: post.cid || 'c' + post.id, attributes: { body: body } }
}

// --------------------------------------
// POSTING
// --------------------------------------
export const UPLOAD_FILE_SIZE_EXCEEDED = 'post/rejectPostsWithFileSizeExceeded'
export const uploadFileSizeExceeded = (file): Action => {
  return { type: UPLOAD_FILE_SIZE_EXCEEDED, file: file }
}
export const OPEN_EXTENSION_REQUIRED_NOTICE = 'showExtensionRequired'
export const openExtensionRequiredNotice = (extensionInstalled): Action => {
  return { type: OPEN_EXTENSION_REQUIRED_NOTICE, extensionInstalled: extensionInstalled }
}
export const CLOSE_EXTENSION_REQUIRED_NOTICE = 'hideExtensionRequired'
export const closeExtensionRequiredNotice = (): Action => {
  return { type: CLOSE_EXTENSION_REQUIRED_NOTICE }
}
export const GET_CONTENT = 'contentPicker/pickContentSource'
export const getContent = (source): Action => {
  return { type: GET_CONTENT, source: source }
}
export const CLOSE_CONTENT_SOURCE = 'contentPicker/hideContentPickerPanel'
export const closeContentSource = (): Action => {
  return { type: CLOSE_CONTENT_SOURCE }
}
export const SAVE_CONTENT = 'post/saveContent'
export const saveContent = (content): Action => {
  return { type: SAVE_CONTENT, content: content }
}
export const CHOOSE_CONTENT_SOURCE = 'contentPicker/showContentSourceMenu'
export const chooseContentSource = (position): Action => {
  return { type: CHOOSE_CONTENT_SOURCE, position: position }
}
export const CLOSE_CONTENT_SOURCE_MENU = 'contentPicker/hideContentSourceMenu'
export const closeContentSourceMenu = (): Action => {
  return { type: CLOSE_CONTENT_SOURCE_MENU }
}

export const REMOVE_POST_ATTACHMENT = 'post/removePostAttachment'
export const removePostAttachment = (post): Action => {
  return { type: REMOVE_POST_ATTACHMENT, postCid: post.cid || 'c' + post.id }
}
// --------------------------------------
// EDITOR
// --------------------------------------
export const RESIZE_EDITOR = 'resizeEditor'
export const resizeEditor = (height): Action => {
  return { type: RESIZE_EDITOR, height: height }
}

// --------
// COMMENTS
// --------
export const CLOSE_COMMENTS_PANEL = 'hideCommentsPanel'
export const closeCommentsPanel = (): Action => {
  return { type: CLOSE_COMMENTS_PANEL }
}
export const CREATE_COMMENT = 'createComment'
export const createComment = (commentAttributes): Action => {
  return { type: CREATE_COMMENT, commentAttributes: commentAttributes }
}
export const EDIT_COMMENT = 'editComment'
export const editComment = (commentAttributes): Action => {
  return { type: EDIT_COMMENT, commentAttributes: commentAttributes }
}
export const DELETE_COMMENT = 'deleteComment'
export const deleteComment = (commentId): Action => {
  return { type: DELETE_COMMENT, commentId: commentId }
}

// ---
// NAV
// ---
// May 2019 - Deprecated
export const SETTINGS_SHOW = 'settingsShow'
export const showSettings = (): Action => {
  return { type: SETTINGS_SHOW }
}

// Apr 2019 - Deprecated
export const SHARE_SHOW = 'shareShow'
export const showShare = (): Action => {
  return { type: SHARE_SHOW }
}

// Jun 2019 - Deprecated
export const REMAKE_SHOW = 'remakeShow'
export const showRemake = (): Action => {
  return { type: REMAKE_SHOW }
}

export const SHOW_WALL_QUOTA_EXCEEDED = 'showWallQuotaExceeded'
export const showWallQuotaExceeded = (): Action => {
  return { type: SHOW_WALL_QUOTA_EXCEEDED }
}

export const HIDE_WALL_QUOTA_EXCEEDED = 'hideWallQuotaExceeded'
export const hideWallQuotaExceeded = (): Action => {
  return { type: HIDE_WALL_QUOTA_EXCEEDED }
}

export const tryRemaking = (): Action => {
  return async (dispatch: Dispatch) => {
    return fetch('/session', { credentials: 'include' })
      .then((response) => response.json())
      .then((data): void => {
        if (data.quota.can_make) {
          dispatch(showRemake())
        } else {
          dispatch(showWallQuotaExceeded())
        }
      })
  }
}
